import { $userProfile } from '@entities/user'
import { useUnit } from 'effector-react'
import { motion, useAnimate, useMotionValueEvent, useScroll } from 'motion/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useState } from 'react'

export function SubscriptionNotify() {
  const user = useUnit($userProfile)
  const t = useTranslations()
  const [scope, animate] = useAnimate()
  const { scrollY } = useScroll({ layoutEffect: true })
  const [visible, setVisible] = useState(true)

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (!visible) return
    if (!scope.current) return

    if (latest > 273) {
      animate(scope.current, { y: 60 })
    } else {
      animate(scope.current, { y: 8 })
    }
  })

  useEffect(() => {
    if (!scope.current) return

    setTimeout(() => {
      animate(scope.current, { y: -100, opacity: 0 })
      setVisible(false)
    }, 10000)
  }, [])

  if (!user) return
  if (user.plan_id !== 0) return

  return (
    <motion.div
      initial={{ y: -100, x: '-50%', opacity: 0 }}
      animate={{ y: 8, x: '-50%', opacity: 100 }}
      transition={{
        delay: 2,
      }}
      className="fixed left-1/2 rounded-full px-4 py-2 z-50 bg-black shadow-md text-white gap-2 flex items-center justify-center"
      ref={scope}
    >
      <div>
        <Image src={'/icons/error.svg'} alt={'add to favorites icon'} width={20} height={20} />
      </div>

      <span>{t('user_notifications_subscription_empty')}</span>
    </motion.div>
  )
}
