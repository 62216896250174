import { createEvent, createStore, sample } from 'effector'

export function createBindingForVisibility() {
  const $inView = createStore(false)
  const $isLoaded = createStore(false)
  const visibilityChanged = createEvent<{
    visible: boolean
    id?: string | number
    shouldBeLoaded?: boolean
    person?: boolean
    query?: string
  }>()
  const $companyId = createStore<string | number | null>(null)
  const $shouldBeLoaded = createStore<boolean>(true)
  const $isPerson = createStore(false)
  const $query = createStore<string | undefined>('')

  sample({
    clock: visibilityChanged,
    fn: ({ visible }) => visible,
    target: $inView,
  })

  sample({
    clock: visibilityChanged,
    fn: ({ id }) => id ?? null,
    target: $companyId,
  })

  sample({
    clock: visibilityChanged,
    fn: ({ shouldBeLoaded }) => (shouldBeLoaded === undefined ? true : shouldBeLoaded),
    target: $shouldBeLoaded,
  })
  sample({
    clock: visibilityChanged,
    fn: ({ person }) => (person === undefined ? false : person),
    target: $isPerson,
  })

  sample({
    clock: visibilityChanged,
    filter: ({ person }) => !!person,
    fn: ({ query }) => query,
    target: $query,
  })

  sample({
    clock: $companyId,
    fn: () => false,
    target: $isLoaded,
  })

  return { $inView, visibilityChanged, $isLoaded, $companyId, $shouldBeLoaded, $isPerson, $query }
}
