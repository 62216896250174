import { deleteCookie, setCookie } from 'cookies-next'
import { createEffect, createEvent, sample } from 'effector'

export interface SetCookieIn {
  name: 'access' | 'refresh' | 'ga'
  value: string
  expires?: Date
}

export const cookiesSet = createEvent<SetCookieIn>()
export const cookiesClear = createEvent()
const setCookieFx = createEffect(async (cookie: SetCookieIn) => {
  setCookie(cookie.name, cookie.value)
})
const clearCookiesFx = createEffect(() => {
  deleteCookie('access')
  deleteCookie('refresh')
})

sample({
  clock: cookiesSet,
  target: setCookieFx,
})

sample({
  clock: cookiesClear,
  target: clearCookiesFx,
})
