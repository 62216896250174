// @ts-nocheck
import { PaginationView } from '@shared/ui/pagination'
import { Cell } from '@tanstack/table-core'
import clsx from 'clsx'
import { Store } from 'effector'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import React from 'react'

import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { countOfCategorySet } from '@/screens'
import { ShadcnButton, TableFilterItem, TableFilters } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'
import { ResponseType } from 'src/shared/api/model'

import { Input } from '@shared/ui/input'
import { useDebounce } from 'use-debounce'
import { renderTableData } from './common'
import {
  $isLoading,
  $relations,
  $relationsPage,
  $relationsQuery,
  $relationsType,
  $totalRelations,
  isCompanyRelations,
  relationsPageChanged,
  relationsQueryChanged,
  relationsTypeChanged,
  visibilityChanged,
} from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((d) => d.VirtualizedTable), {
  ssr: false,
})

const loaderArray = Array(15)
  .fill('')
  .map((e) => ({
    company: <span className="flex h-5 animate-pulse rounded-md bg-gray-200 px-2 text-center" />,
    connection_type: <span className="flex h-5 animate-pulse rounded-md bg-gray-200 px-2 text-center" />,
    parent: <span className="flex h-5 animate-pulse rounded-md bg-gray-200 px-2 text-center" />,
  }))

export function Connections({
  for: $data,
  person,
  relations: $baseRelations,
  isFull: $isFull,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull'> & {
  relations: Store<ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['relations']>
}) {
  const [
    data,
    isFull,
    onVisible,
    baseRelations,
    relations,
    page,
    setPage,
    setCountOfCategory,
    relationType,
    setRelationType,
    relationsQuery,
    relationsSetQuery,
    loading,
  ] = useUnit([
    $data,
    $isFull,
    visibilityChanged,
    $baseRelations,
    $relations,
    $relationsPage,
    relationsPageChanged,
    countOfCategorySet,
    $relationsType,
    relationsTypeChanged,
    $relationsQuery,
    relationsQueryChanged,
    $isLoading,
  ])
  const [filterValue, setFilter] = useState<string | null>(null)
  const [query, setQuery] = useState(relationsQuery)
  const [relationQuery] = useDebounce(query, 500)

  useEffect(() => {
    relationsSetQuery(relationQuery)
  }, [relationQuery, relationsSetQuery])

  useEffect(() => {
    setPage(1)
    setRelationType(filterValue)
  }, [filterValue, setPage, setRelationType])

  const t = useTranslations()
  const { ref } = useInView({
    onChange: (visible) =>
      onVisible({
        visible,
        id: person ? data?.identifier : data?.id,
        shouldBeLoaded: isFull,
        person,
      }),
  })

  const totalRelations = useStoreMap({
    store: $totalRelations,
    fn: (total, [baseRelations, isFull]) => {
      if (isFull) return total

      let baseTotal = 0

      baseRelations?.forEach((c) => (baseTotal += c?.count || 0))

      return baseTotal
    },
    keys: [baseRelations, isFull],
  })

  useEffect(() => {
    setCountOfCategory({
      category: 'connections',
      count: totalRelations,
    })
  }, [setCountOfCategory, totalRelations])

  const filters = useStoreMap({
    store: $relations,
    fn: (relations, [isFull, person, baseRelations]) => {
      const labels = !isFull ? t.raw('connections_filters') : relations?.meta

      let relationsToMap: Record<string, any>[] = []

      if (labels) {
        if (isCompanyRelations(relations)) {
          if (isFull) {
            relationsToMap = Object.entries(labels)
          } else {
            relationsToMap = baseRelations || []
          }
        } else {
          relationsToMap = labels
        }
      }

      return (
        relationsToMap.filter((rel) => {
          const isNullPersonBranches = !person && (rel[0] === 'branches' || rel[0] === 'by_branch')
          const isNullConnectionBranches = rel.connection === 'branches' || rel.connection === 'by_branch'

          if (isFull && !rel.count && person) {
            return false
          }

          if (isFull && !rel[1] && !person) {
            return false
          }

          return !((!isFull && isNullPersonBranches) || (!isFull && isNullConnectionBranches))
        }) as ['filter', number][]
      ).sort((a, b) => b[1] - a[1])
    },
    keys: [isFull, person, baseRelations],
  })

  const relationsTableData = useStoreMap({
    store: $relations,
    keys: [person],
    fn: (relations, [person]) => renderTableData(person, relations?.links, t('all'), data?.jurisdiction || 'kz', t),
  })

  const highlightText = (element: any, query: string) => {
    if (!query) return element
    if (React.isValidElement(element)) {
      const text = element.props.children
      if (typeof text === 'string') {
        const parts = text.split(new RegExp(`(${query})`, 'gi'))
        const highlightedText = parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} className="bg-blue-200">
              {part}
            </span>
          ) : (
            part
          ),
        )
        return React.cloneElement(element, {}, highlightedText)
      }
      return element
    }
    return element
  }

  const tableCell = useMemo(() => {
    return t.raw('connections_head').map((c: any) => {
      if (['company', 'parent', 'connection_type'].includes(c.accessorKey)) {
        return {
          ...c,
          cell: (info: Cell<any, any>) => highlightText(info.getValue(), relationQuery),
        }
      }
      return c
    })
  }, [t, relationQuery])

  const totalRelationsCount = useMemo(() => {
    if (!relationType) {
      return relations?.total
    }

    const meta = relations.meta as Record<string, number>

    return meta[relationType]
  }, [relationType, relations.total, relations.meta])

  return (
    <CollapsibleBlock
      ref={ref}
      title={
        <>
          {t('connections')}
          <span className="ml-2 text-sm font-normal">{totalRelations || ''}</span>
        </>
      }
      dataType="connections"
      id="connections"
      className="select-none"
    >
      <SafeView for={isFull}>
        <div className="col-span-full my-1 space-y-2">
          <div className="col-span-full">
            <ShadcnButton variant="ghost">{t('relation_graph')}</ShadcnButton>
          </div>
          <Input
            className="col-span-full"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t('connections_search_placeholder')}
          />
        </div>
      </SafeView>

      <SafeView for={filters && filters.length}>
        <TableFilters applyFilter={setFilter} isFull={isFull}>
          {({ applyFilter, isFull, ...props }) => (
            <>
              <TableFilterItem {...props} className="" onClick={() => applyFilter('-1')}>
                {totalRelations} {t('all')}
              </TableFilterItem>

              {filters.map((filter, idx) => (
                <TableFilterItem
                  key={idx}
                  {...props}
                  className={clsx({
                    'font-bold': filter[0] === filterValue,
                  })}
                  onClick={() => applyFilter(filter[0])}
                >
                  {isFull ? (person ? filter.count : filter[1]) : filter.count || ''}&nbsp;
                  {isFull
                    ? t.raw('connections_filters')[person ? filter.connection : filter[0]]
                    : person
                      ? filter.count
                      : filter[1] || t.raw('connections_filters')[filter.connection]}{' '}
                </TableFilterItem>
              ))}
            </>
          )}
        </TableFilters>
      </SafeView>

      <SafeView for={!isFull}>
        <div className="col-span-full">
          <VirtualizedTable mocked />
        </div>
      </SafeView>

      <div className="col-span-full">
        <SafeView for={loading}>
          <VirtualizedTable
            list={loaderArray}
            columns={tableCell}
            mocked={!isFull}
            globalFilter={t.raw('connections_filters')[filterValue || '']}
            filterKey="connection_type"
          />
        </SafeView>

        <SafeView for={!loading && relationsTableData.length === 0 && isFull}>
          <div className="text-center text-gray-500">{t('not_found')}</div>
        </SafeView>

        <SafeView for={!loading && relationsTableData.length > 0}>
          <VirtualizedTable
            list={relationsTableData}
            columns={tableCell}
            mocked={!isFull}
            globalFilter={t.raw('connections_filters')[filterValue || '']}
            filterKey="connection_type"
          />
        </SafeView>
      </div>

      <SafeView for={data?.structure === 'se'}>
        <a
          href="https://opi.dfo.kz/p/"
          rel="nofollow"
          target="_blank"
          className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
        >
          {t('open_source', {
            name: 'opi.dfo.kz',
          })}
        </a>
      </SafeView>

      <SafeView for={isFull && relations?.total > 20}>
        <div className="my-6 w-fit">
          <PaginationView totalCount={totalRelationsCount} pageSize={20} onPageChange={setPage} currentPage={page} />
        </div>
      </SafeView>
    </CollapsibleBlock>
  )
}
