import { Store } from 'effector'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { Jurisdiction } from '@/entities/jurisdiction'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { Placeholder } from '@/ui'
import { BuyForm } from '@/widgets'

import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import { useRouter } from 'next/router'
import CopyToClipboard from '../../../../widgets/copyText'
import { MiniBar } from '../mini-bar'
import { ForCompanyOrIndividualProps } from '../types'
import styles from './styles.module.css'
import { CompanyNames, Officers, ShareHolders, TableSection } from './ui'

const legalsForm = {
  ip: ['Индивидуальный предприниматель', 'se'],
  other: [''],
}

export const getIPorOtherLegalForm = (form: string) => {
  if (legalsForm.ip.includes(form)) {
    return 'ip'
  } else {
    return 'other'
  }
}

export function MainInformation({
  for: $data,
  person,
  isFull: $isFull,
}: ForCompanyOrIndividualProps & { isFull: Store<boolean> }) {
  const [data, isFull] = useUnit([$data, $isFull])
  const legalForm = useStoreMap({
    store: $data,
    keys: [],
    fn: (data) => getIPorOtherLegalForm(data?.legal_form || 'other'),
  })
  const { locale } = useRouter()

  const [headCount, headCountTotal, headCountCol] = useStoreMap({
    store: $data,
    keys: [data?.id],
    fn: (data) => {
      const headCountObj: any = {}

      const headCountColObj: any = {}
      const headcountForEmployeesObj: any = []
      let headCountTotal = 0
      data?.headcount
        ?.sort?.(function (a: any, b: any) {
          //@ts-ignore
          return new Date(b.date) - new Date(a.date)
        })
        .reverse()
        .forEach((el: any) => {
          headCountObj[fmt.date(new Date(el.date))] =
            el.count.match(/\d+/g).length > 1
              ? parseInt(el.count.match(/\d+/g)[1])
              : parseInt(el.count.match(/\d+/g)[0])
          headCountTotal +=
            el.count.match(/\d+/g).length > 1
              ? parseInt(el.count.match(/\d+/g)[1])
              : parseInt(el.count.match(/\d+/g)[0])
          headCountColObj[fmt.date(new Date(el.date))] = el.count
          headcountForEmployeesObj.push({
            year: fmt.date(new Date(el.date)),
            count: el.count,
          })
        })

      return [headCountObj, headCountTotal, headCountColObj]
    },
  })

  const t = useTranslations()
  return (
    <div className="max-w-screen lg:max-w-screen relative mt-6" datatype="information">
      <div id="info" className="absolute -top-96" />

      <SafeView for={!isFull}>
        <div>
          <BuyForm />
        </div>
      </SafeView>

      <table className={styles.mainInfoTable}>
        <tbody className="flex flex-col md:gap-2">
          <CompanyNames for={$data} />
          <SafeView for={data?.names} withProps>
            {(names) => (
              //@ts-ignore
              <TableSection title={t('alternative_names')}>{names.map(({ name }) => name)?.join(', ')}</TableSection>
            )}
          </SafeView>

          <TableSection title={t('status')}>
            <SafeView for={isFull} otherwise={<Placeholder jurisdiction={data?.jurisdiction} withGeneration />}>
              <span className={!data?.inactive ? 'text-green' : 'text-red-500'}>{data?.status}</span>
            </SafeView>
          </TableSection>

          <SafeView for={data?.dissolution_date} withProps>
            {(date) => (
              <TableSection title={t('dissolution_date')}>
                <SafeView for={isFull} otherwise={<Placeholder withGeneration jurisdiction={data?.jurisdiction} />}>
                  {fmt.date(new Date(date))}
                </SafeView>
              </TableSection>
            )}
          </SafeView>

          <TableSection title={t('address')}>
            <div className="col-span-full text-base">
              {fmt.address({
                company: data,
                jurisdiction: data?.jurisdiction as Jurisdiction,
                jurisdictionTranslate:
                  data && data.jurisdiction ? (t.raw('jurisdictions')[data.jurisdiction] as string) : '',
              })}
              {data?.addresses?.[0]?.property_type && (
                <span className="pl-2 text-gray-500">
                  ·{' '}
                  {t
                    .raw(`directory.${data.addresses[0].property_type}`)
                    .toString()
                    //@ts-ignore
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </span>
              )}
            </div>
            {data?.structure === 'se' && (
              <a
                href="https://stat.gov.kz"
                target="_blank"
                rel="nofollow"
                className="col-span-full ml-2 text-xs text-gray-500 hover:text-gray-500"
              >
                {t('open_source', {
                  name: 'stat.gov.kz',
                })}
              </a>
            )}
          </TableSection>

          <SafeView for={data?.identifiers} withProps>
            {(identifiers) =>
              identifiers
                //@ts-ignore
                .filter(({ type }) => ['bin', 'бин'].includes((type as string).toLowerCase()))
                //@ts-ignore
                .map(({ type, value }) => (
                  <TableSection title={type} key={type}>
                    <Tooltip>
                      <TooltipTrigger>
                        <span>
                          <CopyToClipboard text={value ?? ''} />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent>{t('copy')}</TooltipContent>
                    </Tooltip>
                  </TableSection>
                ))
            }
          </SafeView>

          <SafeView for={data?.industry}>
            <TableSection title={t('sector')}>{data?.industry}</TableSection>
          </SafeView>

          <TableSection title={t('incorporation_date')}>
            <SafeView for={data?.incorporation_date} withProps otherwise={t('idk')}>
              {(date) => (
                <>
                  {fmt.date(new Date(date))}
                  <span className="text-gray-500">&nbsp; · {fmt.toToday(date, false, locale)}</span>

                  {data?.structure === 'se' && (
                    <a
                      href="https://stat.gov.kz"
                      target="_blank"
                      rel="nofollow"
                      className="col-span-full ml-2 text-xs text-gray-500 hover:text-gray-500"
                    >
                      {t('open_source', {
                        name: 'stat.gov.kz',
                      })}
                    </a>
                  )}
                </>
              )}
            </SafeView>
          </TableSection>

          <SafeView for={data?.managing_company_name} withProps>
            {(managingCompanyName) => (
              <TableSection title={t('management_company')}>
                <a rel="nofollow" href={`/companies/${data?.jurisdiction}/${data?.managing_company_id}`}>
                  <p className="hover:text-orange block w-full cursor-pointer text-primary">{managingCompanyName}</p>
                </a>
              </TableSection>
            )}
          </SafeView>

          <Officers for={$data} isFull={$isFull} />

          <TableSection title={t('type_of_ownership')}>{data?.ownership_type || t('not_found')}</TableSection>

          <ShareHolders for={$data} isFull={isFull} legalForm={legalForm} />
          {['kz', 'uz', 'ru'].includes(data?.jurisdiction as string) && (
            <SafeView for={data?.headcount} withProps otherwise={null}>
              {(hc) => (
                <TableSection title={t('number_of_employees')}>
                  {hc.length > 1 ? (
                    //@ts-ignore
                    <MiniBar
                      type="headcount"
                      jurisdiction={data?.jurisdiction ?? ''}
                      isIndividual={person}
                      headcountCol={headCountCol}
                      data={headCount}
                      totalSum={headCountTotal}
                      isVatPayer
                    />
                  ) : (
                    <>
                      {hc[0]?.count} {t('people')}
                    </>
                  )}
                </TableSection>
              )}
            </SafeView>
          )}

          <TableSection title={t('last_updated_date')}>
            <SafeView for={data?.last_update_date} withProps otherwise={t('idk')}>
              {(date) => fmt.date(new Date(date))}
            </SafeView>
          </TableSection>

          <SafeView for={data?.jurisdiction === 'uz'}>
            <TableSection title={t('authorized_capital')}>
              <SafeView for={isFull} otherwise={<Placeholder withCuntrySgin withGeneration />}>
                {data?.capital?.share_capital
                  ? fmt.number(data.capital.share_capital, data?.jurisdiction as any)
                  : t('not_found')}
              </SafeView>
            </TableSection>
          </SafeView>

          <SafeView for={legalForm !== 'ip'}>
            <TableSection title={t('branches')}>
              <SafeView for={data?.branches} withProps otherwise={t('not_found')}>
                {(branches) =>
                  //@ts-ignore
                  branches.map((branch) => (
                    <a href={`/companies/${data?.jurisdiction}/${branch?.id}`} key={branch?.id}>
                      <p className="block w-full cursor-pointer text-primary hover:text-flamingo">{branch.name}</p>
                    </a>
                  ))
                }
              </SafeView>
            </TableSection>
          </SafeView>
        </tbody>
      </table>
    </div>
  )
}
