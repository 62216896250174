import { createStore, sample } from 'effector'

import { loadCourtCasesFx, loadIndividualCourtCasesFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, visibilityChanged, $companyId, $isLoaded, $shouldBeLoaded, $isPerson, $query } =
  createBindingForVisibility()

export const $courtCases = createStore<ResponseType<'/business/{id}/court_cases', 'get'>>({})
export const $courtCasesBar = $courtCases.map((courtCases) => {
  let totalSum = 0
  const yearsSum: Record<string, number> = {}
  if (courtCases?.year_meta) {
    courtCases?.year_meta?.forEach((e: any) => {
      totalSum += e?.total || 0
      yearsSum[e.type as string] = e?.total || 0
    })
  }
  return { totalSum, yearsSum }
})
sample({
  clock: $inView,
  source: {
    id: $companyId,
    isLoaded: $isLoaded,
    shouldBeLoaded: $shouldBeLoaded,
    isPerson: $isPerson,
  },
  filter: ({ id, isLoaded, shouldBeLoaded, isPerson }, visible) =>
    !isPerson && shouldBeLoaded && visible && !isLoaded && !!id,
  fn: ({ id }) => ({
    path: {
      id: id as string,
    },
    query: {},
  }),
  target: [loadCourtCasesFx],
})

sample({
  clock: $inView,
  source: $query,
  filter: $isPerson,
  fn: (query) => ({
    data: {
      query: query as string,
    },
  }),
  target: [loadIndividualCourtCasesFx],
})

sample({
  clock: [loadCourtCasesFx.doneData, loadIndividualCourtCasesFx.doneData],
  fn: () => true,
  target: $isLoaded,
})

sample({
  clock: [loadCourtCasesFx.doneData, loadIndividualCourtCasesFx.doneData],
  target: $courtCases,
})
