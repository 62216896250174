// @ts-nocheck
import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { $correctIndustry } from '@/screens'
import { $axiosInstance } from '@/shared-events'
import Icon from '@/shared/ui/icon'
import { CopyIcon, FileIcon } from '@radix-ui/react-icons'
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from '@radix-ui/react-popover'
import { ShadcnButton } from '@shared/ui'
import { Input } from '@shared/ui/input'
import { Label } from '@shared/ui/label'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import clsx from 'clsx'
import { getCookies } from 'cookies-next'
import { useStoreMap, useUnit } from 'effector-react'
import FileSaver from 'file-saver'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'
import { generateHeaders } from 'src/shared/api/model'
import CopyToClipboard from '../../../widgets/copyText'
import ModalSelectList from './ModalSelectList'

export function BriefInformation({ for: $data, person, isFull: $isFull }: ForCompanyOrIndividualProps) {
  const t = useTranslations()
  const router = useRouter()

  const [data, identifiers, axios, user, isFull] = useUnit([
    $data,
    $correctIndustry,
    $axiosInstance,
    $userProfile,
    $isFull,
  ])
  const [pdfLoading, setPDFLoading] = useState(false)

  const description = useStoreMap({
    store: $data,
    keys: [],
    fn: (company) => {
      if (company) {
        if (person) {
          if ('identifier' in company) return company.identifier
        } else {
          return fmt.industryCode({ company })
        }
      }
      return ''
    },
  })

  const downloadPdf = async (jurisdiction?: string, id?: number) => {
    setPDFLoading(true)
    try {
      const headers = generateHeaders()
      const url = `${process.env.NEXT_PUBLIC_API_URL}/api/v2/business/pdf/${jurisdiction}/${id}`

      const res = await axios.get(url, {
        method: 'GET',
        headers: {
          ...headers,
          Authorization: getCookies()?.access || localStorage.getItem('token'),
        },
        responseType: 'blob',
      })

      FileSaver.saveAs(res.data, `${jurisdiction}-${id}-${new Date().toLocaleDateString()}.pdf`)
    } catch (e) {
      console.info(e)
    }
    setPDFLoading(false)
  }

  const [existCompany, setExistCompany] = useState(false)
  const [existCompanyData, setExistCompanyData] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getExistCompany = async () => {
    if (!isFull) return
    const token = localStorage.getItem('token')
    const headers = {
      ...generateHeaders(),
      Authorization: `Bearer ${token}`,
    }

    const params = {
      company_ids: data.id,
    }

    const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/user/favourite/items/exists`, {
      headers,
      params,
    })
    setExistCompanyData(res.data.Items)
    setExistCompany(!!res.data.Items)
  }

  useEffect(() => {
    getExistCompany()
  }, [])

  const [currentURL, setCurrentURL] = useState(router.asPath)

  useEffect(() => {
    if (typeof window !== 'undefined') setCurrentURL(window.location.href)
  }, [])

  return (
    <div className="mt-2 flex flex-col justify-between space-y-2 md:flex-row md:space-y-0">
      <ModalSelectList
        isOpen={isModalOpen}
        companyId={data.id}
        getExistCompany={getExistCompany}
        onClose={() => setIsModalOpen(false)}
      />

      <SafeView for={data} withProps>
        {(company) => (
          <div className="flex w-full flex-wrap gap-2">
            <div className="w-full space-y-1">
              <h1 className="w-full font-stem text-xl font-medium xl:text-2xl">{company?.title || company?.name}</h1>
              <h2 className="w-full text-sm leading-relaxed sm:text-base">{description}</h2>
              {identifiers
                .sort((a, b) => (b.value?.includes('-') ? -1 : 1))
                .map((identifier, idx, all) => (
                  <span
                    className="inline-flex w-fit items-center space-x-1 text-center text-base leading-relaxed tracking-widest text-gray-500"
                    key={`${identifier.type}_${idx}_${identifier.type}`}
                  >
                    <span>{identifier.type}</span>
                    <span className="my-auto">
                      <CopyToClipboard text={identifier.value} />
                    </span>
                    {all.length - 1 > idx && <span className="px-1">{' · '}</span>}
                  </span>
                ))}
            </div>
          </div>
        )}
      </SafeView>
      <SafeView for={isFull}>
        <div className="flex w-full flex-row justify-end space-x-2">
          {/*<Tooltip>*/}
          {/*  <TooltipTrigger asChild>*/}
          {/*    <ShadcnButton*/}
          {/*      className="font-sm md:font-md bg-shad-secondary hover:bg-shad-secondary text-shad-secondary w-1/3 rounded-md px-4 py-2 shadow-sm disabled:opacity-50 md:w-20 space-x-2"*/}
          {/*      type="button"*/}
          {/*      onClick={() => {*/}
          {/*        if (!existCompany) {*/}
          {/*          setIsModalOpen(true)*/}
          {/*        } else {*/}
          {/*          deleteItem()*/}
          {/*        }*/}
          {/*      }}*/}
          {/*      disabled={!user}*/}
          {/*    >*/}
          {/*      <BookmarkIcon className="h-full w-full" />*/}
          {/*    </ShadcnButton>*/}
          {/*  </TooltipTrigger>*/}
          {/*  <TooltipContent>{t('add_list')}</TooltipContent>*/}
          {/*</Tooltip>*/}

          <Popover>
            <PopoverTrigger asChild>
              <ShadcnButton
                className="font-sm mb-14 md:font-md bg-shad-secondary hover:bg-shad-secondary text-shad-secondary w-1/3 rounded-md px-4 py-2 shadow-sm disabled:opacity-50 md:w-20 space-x-2"
                type="button"
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CopyIcon className="w-full h-full" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-800 text-white p-2 rounded-md">
                    {t('share_company')}
                  </TooltipContent>
                </Tooltip>
              </ShadcnButton>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent
                updatePositionStrategy="optimized"
                sideOffset={5}
                align="end"
                className="z-[100] w-screen rounded-md border bg-gray-50 p-4 shadow-md md:w-auto"
              >
                <div className="flex flex-col space-y-2 text-center sm:text-left">
                  <h3 className="text-lg font-semibold">{t('share_company')}</h3>
                  <p className="text-sm text-muted-foreground">{t('share_company_description')}</p>
                </div>
                <div className="flex items-center space-x-2 pt-4">
                  <div className="grid flex-1 gap-2">
                    <Label htmlFor="link" className="sr-only">
                      {t('link')}
                    </Label>
                    <Input id="link" defaultValue={currentURL} readOnly className="h-9 shadow-md" autoComplete="off" />
                  </div>
                  <ShadcnButton
                    type="button"
                    onClick={async () => await navigator.clipboard.writeText(currentURL)}
                    className="bg-gray-600 px-3 hover:bg-gray-700"
                  >
                    <span className="sr-only">{t('copy')}</span>
                    <Icon name="copy" className="h-4 w-4" />
                  </ShadcnButton>
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>

          <Tooltip>
            <TooltipTrigger asChild>
              <ShadcnButton
                className={clsx(
                  'font-sm md:font-md bg-shad-secondary hover:bg-shad-secondary text-shad-secondary relative w-1/3 rounded-md px-4 py-2 shadow-sm disabled:cursor-progress md:w-20 space-x-2',
                  {
                    'animate-pulse': pdfLoading,
                  },
                )}
                onClick={() => downloadPdf(data?.jurisdiction, data?.id)}
                disabled={pdfLoading}
                type="button"
              >
                {!pdfLoading ? (
                  <>
                    <FileIcon className="w-full h-full" />
                  </>
                ) : (
                  <Icon name="loader-circle" className="animate-spin" />
                )}
              </ShadcnButton>
            </TooltipTrigger>
            <TooltipContent>{t('download_as_pdf')}</TooltipContent>
          </Tooltip>
        </div>
      </SafeView>
    </div>
  )
}
