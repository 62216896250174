import { createEvent, sample } from 'effector'

import { loginUserFx, signUpUserFx, verifyUserPhoneFx } from '@/api'
import { $access, $expiresAt, $refresh } from '@/entities/user'

import { $userToken, setupAxiosFx } from '@/shared-events'
import { UserInput } from './types'

export const loginUser = createEvent<UserInput>()

sample({
  clock: loginUser,
  fn: (data) => ({ data }),
  target: loginUserFx,
})

sample({
  clock: [loginUserFx.doneData, verifyUserPhoneFx.doneData, signUpUserFx.doneData],
  fn: ({ access_token, expire_at }) => {
    const tokenInfo = {
      name: 'access' as const,
      value: access_token as string,
      expiresAt: new Date(expire_at),
    }
    return tokenInfo
  },
  target: [$access],
})

sample({
  //@ts-ignore
  clock: [loginUserFx.doneData, verifyUserPhoneFx.doneData, signUpUserFx.doneData],
  fn: ({ refresh_token, expire_at }) => ({
    name: 'refresh' as const,
    value: refresh_token,
    //@ts-ignore
    expiresAt: new Date(expire_at),
  }),
  target: [$refresh],
})

sample({
  clock: [loginUserFx.doneData, verifyUserPhoneFx.doneData, signUpUserFx.doneData],
  //@ts-ignore
  fn: ({ expire_at }) => new Date(expire_at),
  target: [$expiresAt],
})

sample({
  clock: [loginUserFx.doneData, verifyUserPhoneFx.doneData, signUpUserFx.doneData],
  fn: ({ access_token }) => access_token,
  target: [$userToken, setupAxiosFx],
})
