import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'

import { SafeView, usePlan } from '@/features'
import fmt from '@/lib/fmt'
import { Placeholder } from '@/ui'

import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import CopyToClipboard from '../../../../../widgets/copyText'
import { ForCompanyOrIndividualProps } from '../../types'
import { TableSection } from './table-section'

export function ShareHolders({
  for: $data,
  legalForm = 'other',
  isFull = false,
}: Pick<ForCompanyOrIndividualProps, 'for' | 'person'> & {
  legalForm: 'ip' | 'other'
  isFull: boolean
}) {
  const t = useTranslations()
  const locale = useLocale()
  const [data] = useUnit([$data])
  const { openPlanModalWindow } = usePlan()
  const redirectToShareholderCompany = (person: boolean | undefined, company_id: any) => {
    if (isFull) {
      if (person && company_id) {
        const rightHash = company_id?.split('+').join('%')
        window.open(`/individuals?identifier=${rightHash}`, '_blank', 'noopener,noreferrer')
      } else if (company_id) {
        window.open(`/companies/${data?.jurisdiction}/${company_id}`, '_blank', 'noopener,noreferrer')
      }
    } else {
      openPlanModalWindow()
    }
  }

  return (
    <SafeView for={legalForm !== 'ip'}>
      <TableSection title={t('beneficiaries')}>
        <SafeView for={isFull} otherwise={<Placeholder withGeneration />}>
          {data?.shareholders
            //@ts-ignore
            ?.sort((a, b) => Number(a.inactive) - Number(b.inactive))
            //@ts-ignore
            .map((e, idx) => (
              <div key={idx}>
                <span className={`cursor-pointer text-primary hover:text-flamingo`}>
                  <span
                    className={e.inactive ? 'text-[#90674f] line-through' : ''}
                    onClick={() =>
                      redirectToShareholderCompany(
                        e.is_person,
                        e.is_person ? e.hash_bin || e.shareholder_company_id : e.shareholder_company_id,
                      )
                    }
                  >
                    {fmt.resolveNamePropertyByLocale(e, locale)}
                  </span>

                  {isFull && e.identifier && !e.is_person && (
                    <span className={`text-gray-500`}>
                      &nbsp;·&nbsp;
                      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                      {t.raw('identifier')[data!.jurisdiction!]['legal']}
                      &nbsp;
                      <Tooltip>
                        <TooltipTrigger>
                          <span style={{ padding: '0 0 0 10px' }}>
                            <CopyToClipboard text={e.identifier} />
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>{t('copy')}</TooltipContent>
                      </Tooltip>
                    </span>
                  )}

                  {isFull && e.start_date && (
                    <span className="text-gray-500">
                      &nbsp;·&nbsp;
                      {fmt.date(new Date(e.start_date))}
                    </span>
                  )}
                </span>
              </div>
            ))}
        </SafeView>
      </TableSection>
    </SafeView>
  )
}
